import React from 'react'

const Dashboard = () => {
  return (
    <>
      <div style={{display:"flex" ,justifyContent:"center", alignItems:"center"}}><h2>Welcome To MR AND MRS PERFECT TRIP Admin Panel !!!!!</h2></div>
    </>
  )
}

export default Dashboard